.custom-icon {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}

.custom-icon img {
  transition: transform 0.3s; /* Transition for the transform property */
  transform-origin: 50% 50%; /* Center the scaling transform */
}

.custom-icon img:hover {
  transform: scale(1.1); /* Scales the image up evenly */
}

.hill-name {
  /* Adjust as needed for positioning below the icon */
  white-space: nowrap; /* Keeps the text in one line */
  overflow: hidden; /* Prevents text overflow */
  /* Adds an ellipsis if the text is too long */
  font-size: 14px; /* Adjust as needed */
  font-weight: 900;
  position: relative;
  top: -10px; /* Adjust as needed */
  /* Adding stroke effect using text-shadow */
  color: #282828; /* Text color */

  text-shadow: 1px -1px 2px #28282869;
  padding-bottom: 20px;
  padding-right: 3px;
  padding-left: 3px;
}

.hill-name.climbed {
  color: #1976d2; /* Color when climbed */
  text-shadow: -1px 1px 2px #000000bd, 1px 1px 2px #000000bd,
    1px 1px 2px #000000bd;
  font-size: 14px;
  font-weight: 900;
}
.hill-name-popup {
  font-weight: 700;
}

.leaflet-tile {
  filter: grayscale(80%);
}

.hill-popup {
  position: relative;
}

.leaflet-pupup {
  position: relative;
  top: 100px !important;
}

button {
  background-color: #1976d2;
  border: none;
  color: white;
  padding: 5px 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 4px;
}
