#map {
  height: 100vh;
  width: 100%;
  position: relative;
  z-index: 1;
}

.MuiBackdrop-root {
  z-index: 1200 !important; /* Ensure the backdrop is below the modal content */
}

.MuiModal-root {
  z-index: 1300 !important; /* Ensure the modal itself is above the backdrop */
}

/* In your App.css or a similar CSS file */

.fade-out {
  animation: fadeOut 0.5s forwards;
}

.fade-in {
  animation: fadeIn 0.5s forwards;
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
